@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.footer {
  padding-top: 6rem;
  display: flex;
  justify-content: center;
  font-family: 'Poppins';
  color: #828a95;
  font-weight: 400;
  width: 100%;
  min-height: 50vh;
  /* background: #f5f5f5; */
  .footer-content {
    display: flex;
    flex-direction: column;
    color: '#1B2021';
    gap: 150px;
    .footer-body {
      display: flex;
      justify-content: center;
      gap: 100px;
      //width: 100%;
      .footer-body-left {
        .logo {
          width: 180px;
          height: 180px;
        }
        .footer-body-left-content {
          display: flex;
          justify-content: center;
          text-align: left;
          gap: 20px;
          flex-direction: column !important;
          .footer-body-left-icons {
            display: flex;
            gap: 26px;
            align-self: flex-start;

            .linkedln-icon {
              cursor: pointer;
            }
            .instagram-icon {
              cursor: pointer;
            }
            .twitter-icon {
              cursor: pointer;
            }
          }
          .footer-body-left-button {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 14px 24px;
            gap: 10px;
            width: 129px;
            height: 49px;
            background: #3d43d2;
            border-radius: 8px;

            .footer-body-left-text {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 21px;
              display: flex;
              align-items: center;
              color: #ffffff;
              flex: none;
              order: 0;
              flex-grow: 0;
            }
          }
        }
      }

      .footer-body-middle {
        display: flex;
        flex-direction: column;
        gap: 40px;
        text-align: start;
        text-decoration: underline;
        justify-content: flex-start;
        .footer-body-middle-title {
          width: 92px;
          height: 18px;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 18px;
          color: #1b2021;
        }

        .footer-body-middle-content {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
      }

      .footer-body-right {
        display: flex;
        flex-direction: column;
        text-align: left;
        gap: 30px;

        .footer-body-right-title {
          width: 87px;
          height: 18px;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 18px;
          color: #1b2021;
          flex: none;
          order: 0;
          flex-grow: 0;
        }

        .footer-body-right-content {
          display: flex;
          flex-direction: column;
          gap: 20px;

          .footer-body-right-address {
            cursor: pointer;
            display: flex;
            align-items: flex-start;
            gap: 10px;
          }
          .footer-body-right-email {
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 10px;
          }
          .footer-body-right-phone {
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 10px;
          }
        }
      }
    }

    .footer-bottom {
      padding-bottom: 4rem;

      .footer-bottom-end-button {
        cursor: pointer;
        .footer-bottom-end-text {
          display: flex;
          gap: 2rem;
          align-items: center;
        }
      }

      .footer-bottom-middle {
        text-decoration: underline;
        cursor: pointer;
      }

      .footer-bottom-right {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
