@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.our-core-values {
  padding-top: 4rem;
  padding-bottom: 2rem;

  .our-core-values-title-body {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 1rem;

    .coreValue-title {
      width: 281px;
      height: 47px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 47px;
      color: #1b2021;
    }

    .coreValue-subtitle {
      padding-bottom: 2rem;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #828a95;
    }
  }

  .our-core-values-body {
    justify-content: space-between;
    gap: 40px;
    width: 100%;

    .our-core-values-content {
      background: #ffffff;
      box-shadow: 2px -3px 4px rgba(0, 0, 0, 0.05),
        -2px 3px 4px rgba(0, 0, 0, 0.05), -2px -3px 4px rgba(0, 0, 0, 0.05),
        2px 3px 4px rgba(0, 0, 0, 0.05);
      border-radius: 11px;

      .our-core-text-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2rem 0 2rem 0;
        gap: 1rem;

        .our-core-text-title {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 21px;
          display: flex;
          align-items: center;
          color: #1b2021;
        }

        .our-core-text-subtitle {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          display: flex;
          align-items: center;
          color: #828a95;
        }
      }
    }
  }
}
