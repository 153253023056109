@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.services {
  padding-top: 2rem;
  padding-bottom: 4rem;
  .services-title {
    display: flex;
    gap: 1rem;
    align-items: center;
    padding-bottom: 6rem;
    height: 23px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #000000;
  }

  .css-ahj2mt-MuiTypography-root {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #828a95;
  }

  /*  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
    min-height: 144px !important;
  }
 */
  .ourservice-title {
    text-align: left;
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 47px !important;
    color: #1b2021 !important;
  }

  .how-we-do-it {
    padding-top: 6rem;
    padding-bottom: 6rem;
    display: flex;
    justify-content: center;

    .how-we-do-it-title {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 42px;
      text-align: center;
      color: #1b2021;
      padding-bottom: 4rem;
    }

    .how-we-do-it-cards {
      justify-content: center;
      gap: 2rem;
      color: #828a95;

      .how-we-do-it-steps-title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        color: #1b2021;
      }

      .card-text {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #828a95;
      }
    }
  }
}
