@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.aboutus-status-body {
  padding-top: 1rem;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  height: 53%;
  background: #ececfb;

  .aboutus-status-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .aboutus-status__title {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 9px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 62px;
      color: #1b2021;
    }

    .aboutus-status_subtitle {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 31px;
      color: #1b2021;
    }
  }
}
