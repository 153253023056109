@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.nav-bar-container {
  background-color: #ececfb;
  color: white !important;
  border-color: #3d43d2 !important;

  .nav-link.selected {
    text-decoration: underline;
    text-decoration-color: #ffff;
  }

  .header-navbar {
    background-color: transparent;

    /*     color: black !important;
    border-color: black !important; */
  }

  .navbar-toggler-icon {
    //background-color: #3d43d2 !important;
    color: white !important;
  }

  .navbar-toggler {
    background-color: #3d43d2 !important;
    border-color: #3d43d2 !important;
  }
}
.nav-link {
  color: black !important;
}

.btn-outline-success {
  --bs-btn-color: #ffff !important;
  --bs-btn-border-color: #3d43d2 !important;
  --bs-btn-hover-color: #fff;
  --bs-btn-bg: #3d43d2 !important;
}

.btn:hover {
  color: #3d43d2 !important;
  background-color: #3d43d2 !important;
  border-color: #3d43d2 !important;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #e2e3f8 !important;
}
