.App {
  min-height: 100vh;
  text-align: center;
  overflow-x: hidden;
}

@media screen and (width: 768px) {
  .App {
    width: 1440px;
  }
}
