.questions-accordion {
  padding: 4rem;
  //color: #828a95;

  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    background: #3d43d2;
    color: #ffffff;
  }

  .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #828a95;
  }

  .css-15v22id-MuiAccordionDetails-root {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #828a95;
  }
}
