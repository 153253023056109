@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.who-we-are {
  padding-top: 4rem;
  background-image: url('../../assets/images/dna_wave-1.gif');
  background-position: center center;

  .who-we-are-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    .who-we-are-content-title {
      padding-top: 1rem;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 47px;
      color: #1b2021;
    }

    .who-we-are-content-subtitle {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #828a95;
    }
  }

  .who-we-are-content-body {
    padding-top: 6rem;
  }
}
