@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.connect-with-us {
  padding-top: 4rem;
  padding-bottom: 4rem;
  background: #ececfb;

  .connect-with-us__content {
    padding: -1rem 2rem 0 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .connect-with-us__content_body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 4rem;

      .connect-with-us__content__title {
        width: 435px;
        height: 94px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 47px;
        color: #1b2021;
      }

      .connect-with-us__content__subtitle {
        width: 393px;
        height: 72px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #828a95;
      }

      .connect-with-us__content__body-item {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
