@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.contactus {
  background: url('../assets/images/contact.jpg');
  background-size: contain;
  /*  background-color: #3d43d2; */
  width: 100%;

  .contact-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 2rem;
    width: 100%;
    background: #f5f5f5 !important;

    .contact-form-main-title {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 47px;
      color: #1b2021;
    }

    .contact-form-body {
      display: flex;
      text-align: left;
      flex-direction: column;
      gap: 3rem;

      .contactUs-reach-us {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 31px;
        color: #1b2021;
      }
      .contact-form-title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 47px;
        color: #1b2021;
      }

      .contact-subtitle {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #828a95;
      }

      .contact-details {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .contact-details-address {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 26px;
          align-items: center;
          color: #1b2021;
        }

        .contact-details-phone {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 26px;
          align-items: center;
          color: #1b2021;
        }

        .contact-address {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #828a95;
        }
        .contact-phone {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #828a95;
        }

        .contact-details-email {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 26px;
          align-items: center;
          color: #1b2021;
        }

        .text-decoration-underline {
          color: #5386e4;
        }
      }
    }

    .css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root {
      width: 97%;
    }
  }
}
