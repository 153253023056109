@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.booking-container {
  background-color: #ececfb;
  font-family: 'Poppins sans-serif';
  color: #828a95;

  .booking-content {
    min-height: 50vh;
  }

  .booking-container__left {
    background-color: #ececfb;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem;
    color: black;

    .booking-container__left__subtitle {
      font-weight: 400;
      font-size: 16px;
      font-family: 'Poppins';
      line-height: 24px;
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
    }

    .booking-container__left__title {
      display: flex;
      justify-content: center;
      font-weight: 700;
      font-size: 48px;
      line-height: 62px;
      margin-bottom: 1rem;
      color: black;
    }

    .booking-container__left__button {
      background-color: #3d43d2;
      border: none;
      border-radius: 5px;
      padding: 1rem 2rem;
      color: #ffff;
      font-size: 1.5rem;
      font-weight: 600;
      cursor: pointer;
    }

    .booking-container__left__button:hover {
      background-color: #f2f2f2 !important;
      color: #3d43d2 !important;
    }
  }

  .booking-container__right {
    height: 703px;
    left: 770px;
    top: 139px;
    /* border: 4px solid #436AF3; */
    border-radius: 300px 300px 0px 0px;

    .companypicture {
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: 576px) {
    .booking-container__left {
      width: 100%;
      height: 100%;
      margin-bottom: 1rem;
    }

    .booking-container__right {
      width: 100%;
      height: 100%;
      margin-bottom: 1rem;

      img,
      svg {
        width: 365px;
      }
    }
  }
}
