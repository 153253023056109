@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.contact-form {
  padding: 4rem !important;
  background: #ececfb;

  .contact-form-main-title {
    width: 188px;
    height: 42px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 42px;
    color: #1b2021;
  }

  .contact-form-field-title {
    display: flex;
    gap: 1rem;
    align-items: center;
    padding-bottom: 3rem;
    height: 23px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #1b2021;
    padding-top: 4rem;
  }

  .contact-form-field {
    display: flex;
    text-align: left;

    justify-content: space-between;
    gap: 1rem;
    padding-bottom: 2rem;
  }

  .css-wb57ya-MuiFormControl-root-MuiTextField-root {
    width: 87% !important;
  }

  .contact-form-field-message-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 2rem;
    padding-bottom: 2rem;

    .contact-form-field-message-container-text {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      color: #828a95;
    }

    .contact-form-button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 14px 48px;
      gap: 10px;
      width: 183px;
      height: 49px;
      background: #3d43d2;
      border-radius: 8px;
      cursor: pointer;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: #ffffff;
      flex: none;
      order: 0;
      flex-grow: 0;
    }
  }
}
