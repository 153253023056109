@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.jumbotron {
  min-height: 50vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .jumbotron-title {
    font-family: 'DM Serif Display';
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 88px;
    color: #ffffff;
  }

  .jumbotron-description {
    max-width: 996px;
    padding: 2rem;
    font-family: 'Maven Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
  }
}
