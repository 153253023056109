@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.core-content {
  min-height: 50vh;
  width: 100%;
  background-color: #f5f5f5;
  padding-top: 4rem;
  font-family: 'Poppins';
  color: #828a95 !important;

  .core-title {
    font-weight: 700;
    font-size: 36px;
    line-height: 62px;
    margin-bottom: 1rem;
    color: #1b2021;
    text-align: center;
  }

  .core-subtitle {
    padding: 0 5rem 0 5rem;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
  }

  .core-content__services {
    justify-content: center;
    padding: 2rem 0;

    .core-content__services__service1 {
      gap: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background: rgba(61, 67, 210, 0.02);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
      border-radius: 7px;
      width: 305px;
      /*  height: 225px; */
      left: 0px;
      top: 0px;
      margin: 2rem;
      padding: 0.6rem 1rem 0.2rem 1rem;

      .core-content__services__service__icon1 {
        padding: 10px;
      }

      .core-content__services__service__title1 {
        text-align: left;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
      }

      .core-content__services__service__description1 {
        text-align: left;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
      }
    }

    .core-content__services__service2 {
      gap: 10px;
      background: #3d43d2;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
      border-radius: 7px;
      width: 305px;
      /*  height: 225px; */
      margin: 2rem;
      padding: 0.6rem 1rem 0.2rem 1rem;

      .core-content__services__service__icon2 {
        padding: 5px;
      }

      .core-content__services__service__title2 {
        text-align: left;
        font-family: 'Poppins';
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #ffffff;
      }

      .core-content__services__service__description2 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        text-align: left;
      }
    }

    .core-content__services__service3 {
      gap: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 305px;
      /*  height: 225px; */
      left: 0px;
      top: 0px;
      background: rgba(61, 67, 210, 0.02);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
      border-radius: 7px;
      margin: 2rem;
      padding: 0.6rem 1rem 0.2rem 1rem;

      .core-content__services__service__icon3 {
        padding: 10px;
      }

      .core-content__services__service__title3 {
        text-align: left;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
      }

      .core-content__services__service__description3 {
        text-align: left;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
      }
    }

    .core-content__services__service4 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 305px;
      /*  height: 225px; */
      left: 0px;
      top: 0px;
      background: rgba(61, 67, 210, 0.02);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
      border-radius: 7px;
      margin: 2rem;
      padding: 0.6rem 1rem 0.2rem 1rem;

      .core-content__services__service__icon4 {
        padding: 10px;
      }

      .core-content__services__service__title4 {
        text-align: left;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
      }

      .core-content__services__service__description4 {
        text-align: left;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }

  .more-page {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    cursor: pointer;

    .more-page-text {
      width: 233px;
      height: 28px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      color: #3136a8;
      flex: none;
      order: 0;
      flex-grow: 0;
    }
  }

  @media screen and (max-width: 576px) {
    .core-content__services {
      flex-direction: column;
      align-items: center;
    }
  }
}
