@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.contract-procedure {
  background: #f5f5f5 !important;
  color: #828a95;

  .contract-procedure-content-body {
    position: relative;
    box-sizing: border-box;
    border: 2px dashed #3d43d2;
    border-radius: 10px;
    color: #828a95;

    .contract-procedure-title {
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 42px;
      color: #3d43d2;
      padding: 2rem 0;
    }

    .contract-procedure-content {
      background: #ececfb;
      width: 100%;
      left: -24px;
      top: 24px;
      padding-left: 2rem;
    }
  }

  .css-ahj2mt-MuiTypography-root {
    text-align: left;
  }
  .contract-procedure-icon {
    img,
    svg {
      width: 100%;
    }
  }
}
