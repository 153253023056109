@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.book-a-section {
  padding-top: 3rem;
  padding-bottom: 3rem;
  background: #ececfb;
  .book-a-section-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .book-a-section-title {
      display: flex;
      justify-content: center;
      text-align: center;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 47px;
      text-align: center;
      color: #1b2021;
    }

    .book-a-section-description {
      padding-top: 2rem;
      display: flex;
      justify-content: center;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      color: #828a95;
    }

    .book-a-section-button {
      cursor: pointer;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 14px 24px;
      gap: 10px;
      width: 350px;
      height: 49px;
      background: #3d43d2;
      border: 1.5px solid #ffffff;
      border-radius: 8px;
      flex: none;
      order: 1;
      flex-grow: 0;

      .book-a-section-button-text {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        display: flex;
        align-items: center;
        color: #ffffff;
        flex: none;
        order: 0;
        flex-grow: 0;
      }
    }
  }
}
