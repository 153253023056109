@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.meet-the-team {
  padding-top: 4rem;
  padding-bottom: 4rem;

  .meet-the-team-title-body {
    display: flex;
    flex-direction: column;
    padding-bottom: 4rem;
    gap: 1rem;

    .meet-the-team-title {
      display: flex;
      justify-content: center;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 35px;
      text-align: center;
      color: #1b2021;
    }

    .meet-the-team-title-subtitle {
      display: flex;
      justify-content: center;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 31px;
      text-align: center;
      color: #828a95;
    }
  }

  .meet-the-team-content {
    display: flex;
    justify-content: center;
    .meet-the-team-image {
      height: 296px;
      background: #f5f5f5;
      border-top-right-radius: 80px;
    }

    .meet-the-team-text-body {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .meet-the-team-text {
        .meet-the-team-text-title {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 23px;
          color: #181a20;
        }

        .meet-the-team-description {
          width: 198px;
          height: 21px;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 21px;
          color: #828a95;
        }

        .meet-the-team-icon {
          display: flex;
          align-items: center;
          color: #1b2021;
        }
      }
    }
  }
}
