@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.about-us-jumbotron {
  display: flex;
  padding-top: 4rem;
  padding-bottom: 6rem;
  background: #3d43d2;

  .about-us-jumbotron__row {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    .about-us-jumbotron__row__col {
      padding-left: 6%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 4rem;

      .about-us-jumbotron__row__col__title {
        width: 100%;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 62px;
        color: #ffffff;
      }

      .about-us-jumbotron__row__col__subtitle {
        width: 100%;
        font-family: 'Maven Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #ffffff;
      }
    }
  }

  .about-us-jumbotron__row__icon {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;

    .about-us-jumbotron__row__icon__img {
      width: 100%;
      height: 100%;
      max-width: 100px;
      max-height: 100px;
    }
  }
}
