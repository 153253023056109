@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.feedback {
  padding: 5rem 0 5rem 0;
  background: #3d43d2;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;

  .feedback-icon {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;
  }
  .feedback-title-col {
    text-align: left;
    padding-bottom: 2rem;
    color: #ffffff;
    .feedback-title {
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 42px;
      color: #ffffff;
    }
  }

  .step-label {
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 19px !important;
    line-height: 27px !important;
    letter-spacing: -0.3px !important;
    color: #ffff !important;
    padding: 60px 0 !important;
  }

  .step-description {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    text-align: left;
  }

  .css-1vyamtt-MuiStepLabel-labelContainer {
    width: 100%;
    color: #ffffff;
  }

  .css-1hv8oq8-MuiStepLabel-label.Mui-active {
    color: #ffffff;
    font-weight: 500;
  }

  .css-1hv8oq8-MuiStepLabel-label.Mui-completed {
    color: #ffffff;
    font-weight: 500;
  }
}
